/* src/index.css or src/App.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  /* font-family: 'Poppins', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue' !important; */
  color: rgba(63, 80, 96, 0.87);
  font-family: 'Public Sans', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue' !important;
  /* padding: 0.1rem 0.4rem; */
  /* font-size: 0.9375rem; */
  /* color: #00a0ff ; */
  /* border-radius: 4px; */
  /* background-color: rgba(0, 160, 255, 0.08);  */
  
}

body {
  padding: 0.1rem 0.4rem;
  min-height: 100%;
  font-size: 90%;
  border-radius: 4px;
  background-color: rgba(151, 163, 171, 0.08) !important;
}

.primary-heading {
  margin: 0px !important;
  font-weight: 500 !important;
  font-size: 1.125rem !important;
  line-height: 1.5556 !important;
  color: #696cff !important;
}
.primary-content {
  margin: 0px !important;
  font-weight: 500 !important;
  font-size: 1.3rem !important;
  line-height: 1.58334 !important;
  color: rgba(22, 22, 22, 0.825) !important;
}
.secondary-content {
  color: rgb(136, 138, 141) !important;
  margin: 0px !important;
  font-weight: 400 !important;
  font-size: 0.9375rem !important;
  line-height: 1.53846154 !important;
}

.link-text {
  color: #696cff;
  font-weight: 500;
  font-size: 0.9375rem;
  line-height: 1.46667;
}

.primary-button {
  background-color: #696cff !important;
  color: #fff;
  padding: 0.5rem 1.25rem;
}